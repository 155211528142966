<template>
    <div>
      
             
<nav class="navbar navbar-expand-lg navbar-light bg-light ">
  <div class="container-fluid">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand" href="/">面试岛</a>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0 custom-navbar-menu">
        <li class="nav-item custom-navbar-item ">
          <a class="nav-link active" aria-current="page" href="/">首页</a>
          
          <div class="nav-link active hand" aria-current="page" href="/" @click="goToVoiceMianShi">视频面试</div>
        </li>
        
        <div v-for="(item, index) in list" :key="index">
          <div v-if="item.children && item.children.length">
            
            <li class="nav-item dropdown" >
            
              <a class="nav-link dropdown-toggle" :href="item.url" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {{ item.name }}
              </a>
            
              <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" v-show="item.children">
                <li v-for="(subItem, subIndex) in item.children" :key="subIndex">
                  <a class="dropdown-item" :href="subItem.url">{{ subItem.name }}</a>
                </li>
              </ul>
            </li>

          </div>
          <div v-else>
            <li class="nav-item">
              
              <a class="nav-link" :href="item.url">{{ item.name }}</a>
            </li>
          </div>
          

        </div>
        
      </ul>
      <form class="d-flex search">
        <SearchComponent @search="handleSearch"/>
      </form>
    </div>
   
     <!-- 登录 -->
      <div class="login" >
           
            <div v-if="checkLogin">
              
              <ul class="navbar-nav me-auto mb-2 mb-lg-0 ">
              <li class="nav-item dropdown" >
              <a class="nav-link dropdown-toggle" href="" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {{ userInfo.nickname}}
              </a>
             
              <ul class="dropdown-menu custom-navbar-login" aria-labelledby="navbarDropdownMenuLink">
                <a class="dropdown-item " @click="goToYoursCenter">个人中心</a>
                <a class="dropdown-item"  @click="goLogout">退出</a>
              </ul>
            </li>
            </ul>
          </div>

          <div v-else>
              <el-button type="primary" plain  @click="goToLogin" >登录</el-button>
          </div>
           
          
      </div>
  </div>
</nav>
        
      </div>
  
   
    
  </template>
  
  <script>

    import {mapState,mapMutations,mapActions,mapGetters} from 'vuex'
    import { setToken, getToken, removeToken } from "@/utils/token";
    import SearchComponent from './SearchComponent.vue';


    
    export default {
      components:{
        SearchComponent
      },
      data() {
        return {
          input: '',
          checkLogin:getToken()?true:false
        }
      },
      mounted() {
       
        try {
           
            this.getList()
            
        } catch (error) {
            this.$alert(error.message);        
        }


      },
      computed: {
        ...mapState('users',['token']),
        ...mapGetters('users',['isLogin','userInfo']),
        ...mapState('nav',['list']),
      },
     
      
      methods: {
        
        ...mapActions('nav',['getNavList']),
        ...mapActions('users',['logout']),
        // 跳转登录
        goToLogin(){
          return this.$router.push({name: 'login'})
        },

        goLogout() {
          console.log("退出登录");
          this.logout().then(res=>{
            if (res == 'success') {
              return this.goToLogin()
            }
           
          })
        },
        goToYoursCenter(){
          return this.$router.push({name: 'yoursCenter'})
        },

        goToVoiceMianShi(){
          return this.$router.push({name: 'voiceMianShi'})
        },

        // 获取导航列表
        getList(){
            this.getNavList().then(res => {
                if (res == 'error') {
                    // 登录成功跳转页面
                    return this.$alert(res.message)    
                }       
            }).catch(res=>{
                this.$alert("服务器错误，请稍后再试！！！") 
            })
        },
        async handleSearch(query) {
          console.log('Search query:', query);

          // 获取当前路由
          const currentRoute = this.$route.path;

          // 构建目标路由
          const targetRoute = { name: 'search', params: { search: query } };

          // 检查目标路由是否与当前路由相同
          if (currentRoute !== this.$router.resolve(targetRoute).href) {
            await this.$router.push(targetRoute);
          } else {
            window.location.reload();
          }
  
        }

      
      }

      
    }
  </script>

  <style lang="scss" scoped>
  .navbar{
    background-color: #fff !important;;

  }

    .custom-navbar-menu {
      margin-left: 2rem;
    }

    .custom-navbar-login {
      cursor: pointer;
      left: calc(50% - 125px);
    }

    .login {
      display: flex;
      align-items: center;
      margin-left: 10rem;

    }
 

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }


    .custom-navbar-item {
      display: flex;
     
    }

   .hand {
      cursor: pointer;

    }

     
  </style>
  
 