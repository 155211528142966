//对于axios进行二次封装
import axios from "axios";
import nprogress, { done } from "nprogress";
//在当前模块中引入store
// import store from '@/store';
import { setToken, getToken, removeToken } from "@/utils/token";

//引入路由相关文件
import router from "@/router";
//如果出现进度条没有显示：一定是你忘记了引入样式了
import "nprogress/nprogress.css";

//底下的代码也是创建axios实例
let requests = axios.create({
  //基础路径
  baseURL: process.env.VUE_APP_API_URL,
  //请求不能超过10s
  timeout: 3000, 
  headers: {
    //请求头信息
    // "Content-Type": "application/x-www-form-urlencoded",
    // "Content-Type": "multipart/form-data",
    "Content-Type": "application/json",
   "x-requested-with": "XMLHttpRequest",
  },
});

//请求拦截器----在项目中发请求（请求没有发出去）可以做一些事情
requests.interceptors.request.use(
  (config) => {
    //需要携带token带给服务器
    let token = getToken()
    if(token){
      config.headers.Authorization =  token;
    }
   

    // nprogress.start();
    return config;
  },(err) => {
    console.log("服务器请求数据失败",err)
    return Promise.reject(err);
  }
);

//响应拦截器----当服务器手动请求之后，做出响应（相应成功）会执行的
requests.interceptors.response.use(
  (res) => {
    let data = res.data;
    
    //进度条结束
    nprogress.done();
    return data;
    // if (data.status == 'success' ){   
    //     //相应成功做的事情
    //     return data;
    // } else {
    //   // router.push({name:'login'})
    //   // return;
    // }
  },
  (err) => {
    // console.log("服务器响应数据失败",err)
    if (err.response) {
      // 服务器响应了状态码，但不在 2xx 范围内
      const { status, data } = err.response;
      switch (status) {
        case 400:
          // console.error('请求错误:', data);
          break;
        case 401:
          console.error('未授权:', data);
          // 重定向到登录页面
          // router.push({ name: 'login' });
          window.location.href = '/login';
          break;
        case 403:
          console.error('禁止访问:', data);
          break;
        case 404:
          console.error('资源未找到:', data);
          break;
        case 422:
          console.error('参数验证失败、登录问题:', data);
          removeToken();
          // 返回上一页
          window.location.href = '/';
          break;
        case 429:
            console.error('请求过多，请稍后再试:', data);  
        case 419:
          console.error('页面过期，请刷新页面:', data);    
        case 500:
          console.error('服务器内部错误:', data);
          break;
        default:
          console.error('未知错误:', data);
      }

    } else if(err.request) {
      // 请求已发出，但没有收到响应
      // console.error('请求超时或网络错误:', error.request);
    } else {
      // 发送请求时发生了一些错误
      // console.error('请求配置错误:', error.message);
    }
    
    // 显示错误提示
    if (err.response && err.response.data) {
      // alert(`请求失败: ${err.response.data.message}`);
      return err.response.data;
    }

    // 记录日志
    // console.error('请求失败:', err);

    return Promise.reject(err);

  }
);
//最终需要对外暴露（不对外暴露外面模块没办法使用）
//这里的代码是暴露一个axios实例
export default requests;
