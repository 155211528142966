import { reqCategoryQuestion } from "@/api";

export default {
  namespaced: true,
  state: () => ({
    categoryInfo: {},
    questions: {},
    page:0
    
  }),
  mutations: {
    setCategoryInfo(state, list) {
      state.categoryInfo = list;
    },

    setQuestion(state, questions) {
      state.questions = questions;
      
    },
    setPage(state, page){
      return state.page = page
    }

  },
  actions: {
    // 获取分类下的问题
    async getCategoryQuestion({ commit }, data) {
      const result = await reqCategoryQuestion(data);
      
      if (result.status == "success") {
        commit("setCategoryInfo", result.data.category);
        commit("setQuestion",result.data.question);
        commit("setPage",result.data.page);
      }
      return result.status;
    }
  },
  getters:{
   
    getQuestionsList(state){
     

      if (typeof state.questions === 'object') {

        return Object.values(state.questions)


      }

      return state.questions;
   
    }
   
  }
}
