import requests from "./ajax";
//登录
//URL:/api/v1/login  method:post phone password
export const reqUserLogin = (data)=>requests({url:'v1/login',data,method:'post'});

//用户信息
export const reqUserInfo = (id)=>requests({url:`v1/users/${id}`,method:'get'});

// export const reqUserRegister = (data)=>requests({url:'v1/register',data,method:'post'});

//退出登录
export const reqUserLogout = ()=>requests({url:'v1/logout',method:'get'});

// 导航栏
export const reqNavList = ()=>requests({url:'v1/navigation',method:'get'});

// 分类
export const reqCategoryList = (data)=>requests({url:`v1/class/${data}`,method:'get'});

//  分类下的问题
export const reqCategoryQuestion = (data)=>requests({url:`v1/category/question/${data.category_id}/${data.page}/${data.serach}`,method:'get'});

// 问题详情
export const reqQuestionDetail = (data)=>requests({url:`v1/question/${data.id}/${data.category_id}`,method:'get'});

// 用户题目标记
export const reqUserQuestionTags = (data)=>requests({url:'v1/question/tags',data,method:'post'});
// 用户题目收藏
export const reqUserQuestionCollect = (data)=>requests({url:'v1/question/collect',data,method:'post'});
// add 浏览量
export const reqQuestionBrowsing = (data)=>requests({url:'v1/question/browsing',data,method:'post'});
// 评论列表
export const reqCommentList = (data)=>requests({url:`v1/questionComment/index/${data.question_id}/${data.page}`,method:'get'});

// 添加评论
export const reqAddComment = (data)=>requests({url:'v1/question/comment/add',data,method:'post'});
// 删除评论
export const reqDelComment = (data)=>requests({url:'v1/question/comment/delete',data,method:'post'});

// 获取二维码
export const reqGetQrcode = (data)=>requests({url:'v1/wechat/buildQrcode',data,method:'post'});

// 获取openid
export const reqGetOpenid = (data)=>requests({url:'v1/wechat/users',data,method:'post'});

// 用户上传
export const reqUserUpload = (data)=>requests(
    {
        url:'v1/users/upload',
        data,method:'post',
        headers:{
            'Content-Type':'multipart/form-data'
        }
    });
// 编辑用户信息
export const reqUserEdit = (data)=>requests({url:'v1/users/edit',data,method:'post'});    

// 搜索
export const reqSearch = (data)=>requests({url:'v1/question/search',data,method:'post'});