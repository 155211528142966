<!-- src/pages/Search/index.vue -->
<template>
    <div class="app">
      <navs />
      <search-list  />
      <footers />
    </div>
  </template>
  
  <script>
  import navs from '../../components/nav.vue';
  import footers from '../../components/footers.vue';
  import SearchList from '../../components/SearchList.vue';
  

  export default {
    name: 'search',
    components: {
      navs,
      footers,
      SearchList,
    },
    data() {
      return {};
    },
   
     
    mounted() {
      console.log('重新');
     

    },
    methods: {
   
    }
   
  };
  </script>
  
  <style lang="scss" scoped>
  .app {
    padding: 20px;
  }
  </style>