import { 
    reqDelComment,
    reqCommentList,
    reqAddComment
 } from "@/api";


export default {

    // 命名空间
    namespaced: true,
    state: () => ({
        list: {},
    }),

    mutations: {
        //  设置评论列表
        getList(state,list){
            state.list = list
            console.log(list);
        }
    },

    actions: {
        //  获取评论列表
        async getCommentList({commit},data){
            const result = await reqCommentList(data)
            if(result.status == 'success'){
                commit('getList',result.data)
                
            }
            return result.status
        },

        //  删除评论
        async delComment({commit},data){
            const result = await reqDelComment(data)
            return result.status
        },

        //  添加评论
        async addComment({commit},data){
            const result = await reqAddComment(data)

            return result.status
        }
    },
    getters: {
    
    },
 

    
}