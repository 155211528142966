<template>
  <div class="app">
    <navs />
    <div class="main">
      <!-- 嵌套的 iframe -->
      <iframe ref="iframe" :src="iframeSrc" class="iframe" width="100%" height="90%" frameborder="0" @load="onIframeLoad" allow="microphone"></iframe>
    </div>
    <!-- <footers /> -->
  </div>
</template>

<script>
import navs from '../../components/nav.vue'
import footers from '../../components/footers.vue'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { getToken } from "@/utils/token";

export default {
  components: { navs, footers },
  name: 'VoiceMianShi',
  data() {
    return {
      token: getToken(),
      baseUrl: "http://vrm.mianshidao.gzy"
    };
  },
  computed: {
    iframeSrc() {
      return this.baseUrl;
    }
  },
  methods: {
    onIframeLoad() {
      const iframeWindow = this.$refs.iframe.contentWindow;
      const targetOrigin = new URL(this.iframeSrc).origin; // 动态获取目标源
      iframeWindow.postMessage({ type: 'TOKEN', token: this.token }, targetOrigin);
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 60px);
}
</style>