
// 导入api
import {
  reqSearch
} from "@/api";


export default {
  namespaced: true,

  //登录与注册的模块
  state: () => ({
    
    list: {}
    
  }),
  // 
  mutations: {
    getData(state, data) {
       
      state.list = data;
      
    },
    
  },

  actions: {
    /**
      * 列表
      * @param {*} param0 
      * @param {*} data 
      * @returns 
      */
    async getQuestionList({ commit },data) {
      let result = await reqSearch(data);

      if (result.status == 'success') {
        
        commit("getData", result.data);
       
      } 

      return result;

    },

   
  },
  // 计算属性
  getters: {

   
   
  }
}



