
// 导入api
import {
  reqUserLogin,
  reqUserRegister,
  reqUserLogout,
  reqGetOpenid,
  reqGetQrcode,
  reqUserUpload,
  reqUserInfo,
  reqUserEdit,

} from "@/api";


import { setToken, getToken, removeToken } from "@/utils/token";
import {setCache,getCache,removeCache} from "@/utils/cache";
export default {
  namespaced: true,

  //登录与注册的模块
  state: () => ({
    token: getToken()?? "", // token
    // userInfo: getCache('userInfo') ?? "", // 用户信息
    // isLogin: false, // 是否登录
    qrcode: getCache('qrcode') ?? {},
    openId:  "",
  }),
  // 
  mutations: {
    token(state, token) {
       
      state.token = token;
      //持久化存储token
      setToken(token);
     
    },
    setUserInfo(state, userInfo) {
      setCache('userInfo',JSON.stringify(userInfo));
     
      // state.userInfo = getCache(userInfo)
      
    },
    setQrcode(state, qrcode) {
      state.qrcode = qrcode;
      setCache('qrcode',JSON.stringify(qrcode));
    },
    
    setOpenId(state, openId) {
      state.openId = openId;
    },

    /**
     * 删除用户信息
     */
    deleteUserInfo(state) {
      console.log("删除用户信息");
        removeToken();
        removeCache('userInfo');
    },

    // setIsLogin(state,isLogin) {

    //     state.isLogin = isLogin;
    // }



  },

  actions: {
    /**
      * 登录
      * @param {*} param0 
      * @param {*} data 
      * @returns 
      */
    async authLogin({ commit }, data) {
      let result = await reqUserLogin(data);

      if (result.code == 201) {
        console.log(result);
        //用户已经登录成功且获取到token
        commit("token", result.data.token);
        
        commit("setUserInfo",  result.data.userInfo);
        // commit("setIsLogin", true);
        
  
        
      } else {
       
        commit('deleteUserInfo')
      }

      return result.status;

    },

    async getOpenid({ commit }, data) {
      let result = await reqGetOpenid(data);
      if (result.status == 'success') {
        commit("setOpenId", result.data.openId);
       
        return result;
      }
      return result

    },
    

    /**
     * 登出
     * @param {*} param0 
     */
    async logout({ commit }) {

      let result = await reqUserLogout();
      if (result.status == 'success') {
        commit('deleteUserInfo')
        // commit("isLogin", false);
        return result.status;
      }
      return "error"
    },

    /**
     * 获取二维码
     * @param {*} param0 
     * @returns 
     */
    async buildQrcode({ commit },data) {

      let result = await reqGetQrcode(data);
      console.log(result);

      if (result.status == 'success') {
        let info = {
          "url":result.data.url,
          "uuid":result.data.ticket.uuid,
          "expire_time":result.data.ticket.expire_time
        }
        commit('setQrcode',info)
        // commit('setQrcodeUrl',result.data.url)
        // commit('setQrcodeuUid',result.data.ticket.uuid)

        return result;
      }
      return result
    },

    /**
     * 获取用户信息
     * @param {*} param0 
     * @param {*} data 
     * @returns 
     */

    async getUserInfo({commit},data){
      const result = await reqUserInfo(data)
      if(result.status == 'success'){
        console.log(result.data)
        commit('setUserInfo',result.data)
      }
      return result
    },

    /**
     * 上传头像
     */
    async uploadAvatar({commit},data){
      const result = await reqUserUpload(data)
     
    
      return result
    },

    /**
     * 编辑用户
     */
    async editUser({commit},data){
      const result = await reqUserEdit(data)
     
      return result
    },



   
  },
  // 计算属性
  getters: {
    userInfo(state){
      // let userInfo=state.userInfo
      // if (userInfo==0) {
      //   userInfo = getCache('userInfo')
      // }
      return JSON.parse(getCache('userInfo'))
    },
    gettersQrcode(state){
      return JSON.parse(state.qrcode)
    },
    isLogin(state){
      if (state.token.length > 0) {
        return true
      }
      return false
    }

  }
}



