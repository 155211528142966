<template>
  <div>
    <!-- 抽屉按钮 -->
    <button class="btn" @click="toggleDrawer">欢迎同学们讨论</button>
    <div  v-if="isDrawerOpen" class="drawer comment-md">
      <div class="comment-box">
        <div class="title">
              <span>评论</span>（{{ list.length }}）
            </div>
        <div class="comment-edit-box">
            <!-- 评论框 -->
            <div class="comment-edit">
            
                <div class="user-logo">
                  <img src="../assets/images/user-logo.png" alt=""></img>
                  
                </div>
                <div class="comment-textarea">
                  <textarea class="form-control" id="exampleFormControlTextarea2" v-model="commentContent" placeholder="请添加评论" rows="4"></textarea>
                  <button type="button" class="btn btn-outline-info" @click="addCommentContent">评论</button>
                </div>

            </div>    
        </div>
        <!-- 评论列表 -->
        <div class="comment-list">
          <commentItem  :list="list" @addCommentEvent = "addCommentReply"></commentItem>
         
        </div>
      </div> 
    </div>
    <!-- 背景遮罩 -->
    <div v-if="isDrawerOpen" class="drawer-backdrop" @click="toggleDrawer"></div>
    
  </div>
</template>

<script>
import { mapState,mapActions } from "vuex";
import commentItem from "./commentItem.vue";
export default {
  name: "comment",
  components:{
    commentItem
  },
  data() {
    return {
      isDrawerOpen: false,
      isReplyEdit: false,
      commentContent:"",
      isComponentVisible: true,
      
    };
  },
  mounted() {

  },
  computed: {
    ...mapState("comment", ["list"]),
  },

  methods: {
    ...mapActions("comment", ["getCommentList","addComment"]),

     // 添加回复
     async addCommentReply(content,id,index){
      console.log('addCommentReply::',content,id,index)

      if (content.length <= 0) {
        return this.$alert("回复内容不能少于5个字")
      }

      let replyCommit = content[index]; 
      
      if (replyCommit.length < 5 || replyCommit.length > 50) {
        return this.$alert("评论内容不能少于5个字")
      }
      this.add(id,replyCommit)

    },

    // 获取评论列表
    async getComment(pageNum = 1) {
      this.getCommentList({
        "question_id": this.$route.params.id,
        'page':pageNum
      }).then(
        res => {
          if (res == "error") {
            return this.$alert(res.message)   
          }
        }
      ).catch(res=>{
        return this.$alert("服务器错误，请稍后再试！！！") 
      }
      ) 
    },
    // 添加评论
    async add(parent_id = 0,replyCommit = "") {
      
    
      let data = {
       'question_id':this.$route.params.id,
        'parent_id':parent_id,
        'content':replyCommit,
      }
     
      this.addComment(data).then(res => {
        if (res == "error") {
          return this.$alert(res.message)   
        }
        this.getComment()
        this.isComponentVisible = true
        return this.$alert("评论成功") 
      }).catch(res=>{
        return this.$alert("服务器错误，请稍后再试！！！") 
      })
    },
    // 添加评论
    async addCommentContent(){

      if (this.commentContent.length < 5 || this.commentContent.length > 50) {
        return this.$alert("评论内容不能少于5个字")
      }

      this.add(0,this.commentContent)
    },

   
    async toggleDrawer() {
      this.isDrawerOpen = !this.isDrawerOpen;
      if (this.isDrawerOpen) {
        this.getComment()
      }
    },

   
  }
};
</script>

<style lang="scss" scoped>


.drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0px);
  overflow-y: auto; /* 垂直滚动条 */
}

.comment-textarea {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: end;
      margin-top: 1rem;
      width: 100%;
      
      textarea {
        background-color: #F8F9FB;
      }
      button {
        margin-top: 0.5rem;
      }
}

 .comment-box {
  display: flex;
  flex-direction: column;
  .title {
    font-size: 20px;
    color: #333;
    text-align: left;
    border-bottom: 1px solid #eaecef;
    margin-top: 1rem;
    margin-left: 1rem;
  }
  
  .comment-list {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    margin-top: 1rem;
    margin-left: 1rem;
    
    width: 100%;
    /* font-weight: bold; */
    font-size: 16px;
    color: #333;
   
   
    .comment-item {
      display: flex;
      flex-direction: column;
      /* justify-content: space-around; */
      margin-top: 1rem;

      .comment-content-text {
        text-align: left;

      }

      .comment-user-info {
        display: flex;
        flex-direction: row;
        justify-content: left;
        /* align-items: center; */
        margin-bottom: 1rem;;
      }

      .comment-content-reply {
        margin-left: 1rem;
      }

      .comment-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: left;
        width: 80%;
      
        .comment-user {
          color: #999;
         
        }
        .comment-time {
          display: inline-block;
          margin-left: 0.5rem;
          color: #999;
        }
      }
    }

  }


  .comment-edit-box {
  
    padding: 1rem;
    height: 100%;
    width: 100%;
    overflow-y: auto; /* 允许内容滚动 */
    z-index: 999;
  

  
  .comment-edit {
    display: flex;
    flex-direction: row;
    justify-content: left;
   
  }

  
  }

 }



.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.drawer-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px;
   z-index: 999; 
}

@media (max-width: 768px) {
    .comment-md {
        min-width: 70%;
    }
}

</style>