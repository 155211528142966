import Vue from 'vue';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import 'font-awesome/css/font-awesome.min.css';
import './assets/public.scss';

import hljs from 'highlight.js'; // 引入 highlight.js
Vue.use(hljs)
//引入路由相关文件
import router from "@/router";
//引入仓库进行注册
import store from "@/store";

Vue.use(ElementUI);

/* 调整缩放比例 end */
new Vue({
  //router进行注册
  router,
  //注册store
  store,
  render: h => h(App),
}).$mount('#app')
