<!-- src/components/SearchList.vue -->
<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h5>搜索结果:{{ this.list.total}} <span>条</span></h5>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>序号</th>
                  <th>问题</th>
                  <th>排序</th>
                  <th>难度</th>
                </tr>
              </thead>
              <tbody>
              
                <tr v-for="(items, index) in displayedItems" :key="index" @click="goToQuestions(items)">
                  <td>{{ (currentPage - 1) * pageSize + index + 1 }}</td>
                  <td>{{ items.question }}</td>
                  <td>{{ items.sort }}</td>
                  <td v-if="items.level==0">简单</td>
                  <td v-else-if="items.level==1">中等</td>
                  <td v-else>困难</td>       
                </tr>
                <tr v-if="loading" key="loading">
                  <td colspan="4" class="text-center">加载中...</td>
                </tr>
                <tr v-else-if="!items.length" key="empty">
                  <td colspan="4" class="text-center">暂无数据</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div ref="observer" class="observer"></div>
   

  </div>
</template>

<script>
import {mapState,mapMutations,mapActions,mapGetters} from 'vuex'
import { getCache, setCache, removeCache } from "@/utils/cache";


export default {
  name: 'SearchList',
  data() {
    return {
      items:[],
      displayedItems: [],
      currentPage: 1,
      pageSize: 20,
      loading: false,
    };
  },

  computed: {
    ...mapState('search',['list'])
  },
  mounted() {
   
    this.loadMore();
    this.observe();
  },
  methods: {
    ...mapActions('search',['getQuestionList']),
    loadMore() {
      
      if (this.currentPage > this.list.page) {
        this.loading = false;
        return;
      }
      if (this.loading) return;
      this.loading = true;


      setTimeout(() => {
        // const start = (this.currentPage - 1) * this.pageSize;
        // const end = start + this.pageSize;
       
        this.getList();
        this.currentPage++;
       
      }, 1000); // 模拟异步加载
     
    },

   
    // 获取列表
    getList(){
        
        this.getQuestionList({
          'page':this.currentPage,
          'question':this.$route.params.search ??getCache('search')
        }).then(res => {
            if (res.status == 'error') {
                return this.$alert(res.message)
                
            } else {
             
              this.items = res.data.list;
              this.displayedItems = [...this.displayedItems, ...this.items];
             
              this.loading = false;
                
            }
            }).catch(res=>{
              console.log(res);
            })
    },
    observe() {
      
      
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          this.loadMore();
        }
      });
      observer.observe(this.$refs.observer);
    },

    goToQuestions(items) {
      this.$router.push({name: 'questionsInfo', params: {id: items.id}})
    },

   
  

  },
};
</script>

<style scoped lang="scss">
.card {
  margin-bottom: 20px;
  
 
}
.observer {
  height: 1px;
  visibility: hidden;
}
tbody {
  tr {
    cursor: pointer;
  }
 
}
</style>