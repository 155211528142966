//引入Vuex -----相当于咱们最大的仓库
import Vuex from "vuex";
//引入Vue
import Vue from "vue";
//使用插件
Vue.use(Vuex);
// 引入用户模块
import users from "./users";  
// 引入导航模块
import nav from "./nav";
// 引入类别模块
import category from "./category";
// 引入类别下的问题
import categoryQuestion from "./categoryQuestion";
// 引入问题
import question from "./question";
// 评论
import comment from "./comment";
// search
import search from "./search";


//需要暴露Vuex.Store类的实例(你需要暴露这个类的实例，如果你不对外暴露，外部是不能使用的)
export default new Vuex.Store({
    //模块：把小仓库进行合并变为大仓库
    modules: {
      users,
      nav,
      category,
      categoryQuestion,
      question,
      comment,
      search
    },
  });